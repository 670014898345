<template>
  <!-- Main content -->
  <section class="section">
    <div v-if="$store.getters.hasSpelling && false" class="notification is-warning is-light content">
      <h3>Notifications</h3>
      <p><b>Spelling Shed Android App Released</b></p>
      <p>Our app is now available for Android devices via the <a href="https://play.google.com/store/apps/details?id=com.edshed.spellingshed">Play Store</a> and <a href="https://www.amazon.co.uk/gp/product/B084RM2RWH">Amazon App Store</a>.</p>
      <p><b>Spelling Scheme Changes</b></p>
      <p>
        Please note that we have made some changes in our spelling scheme to better ensure full coverage of the curriculum. Our scheme is now 36 weeks for all year groups. Stage 1 in particular has changed significantly but all old lists are still in our system, accessible from <router-link to="/users/spellingshed">
          @SpellingShed
        </router-link>.
      </p>
    </div>

    <div class="columns is-multiline">
      <!--<div class="column is-3-widescreen is-6-desktop" v-if="$store.getters.hasSpelling">
            <div class="info-box">
              <span class="info-box-icon bg-aqua">
                <i class="ion ion-ios-book-outline"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text">Spelling Scheme</span>
                <span class="info-box-number">View scheme</span>
                <router-link to="/scheme" class="pull-right">VIEW
                  <i class="ion ion-android-arrow-dropright-circle"></i>
                </router-link>

              </div>
            </div>
          </div> -->

      <div class="column is-6-desktop">
        <div class="info-box">
          <span class="info-box-icon bg-green">
            <i class="ion ion-ios-refresh" />
          </span>
          <div class="info-box-content">
            <span v-if="!$store.getters.hasFullSubscription" class="info-box-number">FREE TRIAL</span>
            <span v-else class="info-box-number">SUBSCRIPTION</span>
            <div>
              <span v-if="$store.getters.hasSpelling" class="tag is-warning">SPELLING</span>
              <span v-if="$store.getters.hasNumber" class="tag is-number">{{ $store.state.user.locale === 'en_US' ? 'MATH' : 'MATHS' }}</span>
              <span v-if="$store.getters.hasEdShed && !isPremium && $store.getters.hasSpelling" class="tag is-info">STANDARD</span>
              <span v-if="$store.getters.hasLitShed" class="tag is-dark">LITERACY SHED +</span>
              <!-- <span class="tag is-success">ACTIVE</span> -->
            </div>
            <router-link v-if="user.school.admin" to="/manage-subscription" class="pull-right">
              <i class="ion ion-android-arrow-dropright-circle" />
            </router-link>
            <span v-if="expiryDate" class="info-box-text">{{ hasCancelled || !$store.getters.hasFullSubscription || $store.getters.isExpiring ? 'EXPIRING' : 'AUTORENEW' }}: {{ expiryDate }}</span>
          </div>
        </div>
        <div class="info-box">
          <span class="info-box-icon bg-yellow">
            <i class="ion ion-ios-cart" />
          </span>
          <div class="info-box-content">
            <span class="info-box-number">STORE</span>
            <a href="https://www.edshed.com/store">
              <figure class="image" style="margin: 0">
                <img src="/img/merchBanner.png" style="float: left; height: 60px;width: auto;">
              </figure>
            </a>
            <a href="https://www.edshed.com/store" class="pull-right" style="margin-top: 35px;">
              <i class="ion ion-android-arrow-dropright-circle" />
            </a>
            <!-- <p class="has-text-right"><router-link to="/store" class="button is-link"><span>Store</span> <span class="icon"><i class="fas fa-chevron-right"></i></span></router-link></p> -->
          </div>
        </div>
      </div>
      <!-- <div class="column is-3-widescreen is-6-desktop" v-if="$store.getters.hasSpelling">
            <div class="info-box">
              <span class="info-box-icon bg-red">
                <i class="ion ion-android-phone-landscape"></i>
              </span>
              <div class="info-box-content">
                <span class="info-box-text">Mobile Apps</span>
                <a href="https://itunes.apple.com/app/spelling-shed/id1264568098?ls=1&mt=8" target="_blank">
                  <img src="/img/Download_on_the_App_Store_Badge_US-UK_135x40.svg" alt="Logo" class="appStoreBadge" style="max-width:90%">
                </a>
              </div>
            </div>
          </div>
          <div class="column is-3-widescreen is-6-desktop">
              <div class="info-box">
                <span class="info-box-icon bg-yellow">
                  <i class="ion ion-ios-game-controller-b-outline"></i>
                </span>
                <div class="info-box-content">
                  <span class="info-box-text">Online Game</span>
                  <p><a href="https://play.edshed.com" target="_blank" class="is-size-7">https://play.edshed.com</a></p>

                  <p><a href="https://play.edshed.com" target="_blank" class="pull-right">PLAY
                    <i class="ion ion-android-arrow-dropright-circle"></i></a></p>
                </div>
              </div>
            </div> -->

      <!-- <div class="column  is-3">
        <div class="box box-white content">
            <h2 class="is-marginless">Store</h2>
            <figure class="image" style="margin: 10px 0">
              <img src="/img/merchBanner.png">
            </figure>
            <p class="has-text-right"><router-link to="/store" class="button is-link"><span>Store</span> <span class="icon"><i class="fas fa-chevron-right"></i></span></router-link></p>
        </div>
        </div> -->
      <div v-if="$store.getters.hasEdShed" class="column is-3-desktop content">
        <h3>Guides</h3>
        <div v-if="$store.state.user.locale === 'en_US'">
          <p>Quick Start Guide - pdf <a href="https://files.edshed.com/docs/QuickStart-US.pdf" class="button is-small is-pulled-right"><i class="fas fa-file-pdf" /></a></p>
          <p>Quick Start Guide - pptx <a href="https://files.edshed.com/docs/QuickStart-US.pptx" class="button is-small is-pulled-right"><i class="fas fa-file-powerpoint" /></a></p>
          <p>School Training - pptx <a href="https://files.edshed.com/docs/Training.pptx" class="button is-small is-pulled-right"><i class="fas fa-file-powerpoint" /></a></p>
        </div>
        <div v-else>
          <p>Quick Start Guide - pdf <a href="https://files.edshed.com/docs/QuickStart.pdf" class="button is-small is-pulled-right"><i class="fas fa-file-pdf" /></a></p>
          <p>Quick Start Guide - pptx <a href="https://files.edshed.com/docs/QuickStart.pptx" class="button is-small is-pulled-right"><i class="fas fa-file-powerpoint" /></a></p>
          <p>School Training - pptx <a href="https://files.edshed.com/docs/Training.pptx" class="button is-small is-pulled-right"><i class="fas fa-file-powerpoint" /></a></p>
        </div>
      </div>
      <div v-if="$store.getters.hasEdShed" class="column is-3-desktop content">
        <h3>Training</h3>
        <iframe
          width="100%"
          height="150"
          src="https://www.youtube.com/embed/922d3RTof8M"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
        />
      </div>
      <div v-if="$store.getters.hasEdShed" class="column is-12-widescreen is-12-desktop is-12-tablet">
        <div class="">
          <!-- <div class="box-header with-border content">
                  <h2>Activity</h2>
                </div> -->
          <div class="box-body content">
            <!-- <h2>Activity</h2> -->
            <div id="chart1" class="ct-chart ct-activity" style="height:150px" />
            <p class="pull-right is-size-7 has-text-gray">
              <b>Gameplays by date</b>
            </p>
          </div>
        </div>
      </div>
      <div v-if="$store.getters.hasLitShed" class="column is-12 content">
        <div class="box">
          <h3>LITERACY SHED +</h3>
          <b-field position="is-centered">
            <b-input v-model="litshedSearchText" placeholder="Search..." type="search" icon="magnify" expanded />
            <p class="control">
              <button class="button is-link" @click="searchLitshed">
                Search
              </button>
            </p>
          </b-field>
        </div>
      </div>

      <div v-if="topGroups && topGroups.length === 0 && (!$store.state.user.school.wonde_id && !$store.state.user.school.clever_oid) && 1 == 0" class="column is-6">
        <div class="box box-white content">
          <!-- <router-link to="/groups" tag="button" class="btn button is-success is-pulled-right">View Groups
            <i class="fa fa-chevron-right"></i>
          </router-link> -->
          <h2 class="is-marginless">
            Welcome!
          </h2>
          <p>Let's get you set up.</p>
          <p>
            First, you need to add Teachers: <router-link to="/teachers" tag="button" class="button is-primary is-small">
              <span>Add Teachers</span> <span class="icon"><i class="fas fa-chevron-right" /></span>
            </router-link>
          </p>
          <p>
            Then, go to the Groups section to create your groups and add {{ $store.state.user.locale === 'en_GB' ? 'pupils' : 'students' }}: <router-link to="/groups" tag="button" class="button is-success is-small">
              <span>Add Groups</span> <span class="icon"><i class="fas fa-chevron-right" /></span>
            </router-link>
          </p>
          <p><a href="https://www.youtube.com/watch?v=mcuYYtqttIE">View tutorial video on YouTube ></a></p>
        </div>
      </div>
      <div v-else class="column is-6">
        <div class="box box-white content">
          <h2 class="is-marginless">
            Merchandise Store
          </h2>
          <figure class="image" style="margin: 10px 0">
            <img src="/img/merchBanner.png" style="max-width: 400px">
          </figure>
          <p><small>Visit our merchandise store to purchase Spelling Shed reward items for {{ $store.state.user.locale === 'en_GB' ? 'pupils' : 'students' }} including stickers, pencils, wristbands, bookmarks and pens.</small></p>
          <p class="has-text-right">
            <a href="https://www.edshed.com/store" class="button is-link">
              <span>Store</span> <span class="icon"><i class="fas fa-chevron-right" /></span>
            </a>
          </p>
        </div>
      </div>

      <div v-if="subsData && !$store.getters.hasLitShed" class="column is-6">
        <div class="box box-white content">
          <h2>Literacy Shed +</h2>
          <figure class="image" style="margin-left: 0; margin-right: 0; margin-top: 0;">
            <img src="/img/litshedbanner.jpg">
          </figure>

          <h4>Teaching resources for English reading and writing, VIPERS and more.</h4>
          <p />
          <p>
            <a href="https://www.literacyshedplus.com" class="button is-link"><span>Visit</span> <span class="icon"><i class="fas fa-chevron-right" /></span></a> <router-link to="/manage-subscription" class="button is-success">
              <span>Upgrade</span> <span class="icon"><i class="fas fa-chevron-right" /></span>
            </router-link>
          </p>
        </div>
      </div>

      <div v-if="subsData && canTrialSpelling" class="column is-6">
        <div class="box box-white content">
          <h2>Spelling Shed Trial</h2>
          <figure class="image" style="margin-left: 0; margin-right: 0; margin-top: 0;">
            <img src="/img/spellingshedbanner.jpg">
          </figure>

          <h4>Start a free 30 day trial of Spelling Shed now.</h4>
          <p><small>You will not be charged unless you choose to subscribe fully. Our standard <a href="https://www.edshed.com/terms">terms and conditions</a> apply.</small></p>
          <button class="button is-warning" @click="startSpellingTrial">
            <span>Start Trial</span> <span class="icon"><i class="fas fa-chevron-right" /></span>
          </button>
        </div>
      </div>

      <div v-if="subsData && canTrialMaths" class="column is-6">
        <div class="box box-white content">
          <h2>{{ $store.state.user.locale === 'en_US' ? 'Math' : 'Maths' }} Shed Trial</h2>
          <figure class="image" style="margin-left: 0; margin-right: 0; margin-top: 0;">
            <img :src="$store.state.user.locale === 'en_US' ? '/static/img/mathshedbanner.jpg' : '/static/img/mathsshedbanner.jpg'">
          </figure>

          <h4>Start a free 30 day trial of {{ $store.state.user.locale === 'en_US' ? 'Math' : 'Maths' }} Shed now.</h4>
          <p>N.B. Only our free sample resources are available with a free trial.</p>
          <p><small>You will not be charged unless you choose to subscribe fully. Our standard <a href="https://www.edshed.com/terms">terms and conditions</a> apply.</small></p>
          <button class="button is-warning" @click="startMathsTrial">
            <span>Start Trial</span> <span class="icon"><i class="fas fa-chevron-right" /></span>
          </button>
        </div>
      </div>
    </div>

    <div class="columns is-multiline">
      <!-- <div class="column is-3">
        <div class="box box-white content">
            <h2 class="is-marginless">Merchandise Store</h2>
            <figure class="image" style="margin: 10px 0">
              <img src="/img/merchBanner.png">
            </figure>
            <p><small>Visit our merchandise store to purchase Spelling Shed reward items for pupils including stickers, pencils, wristbands, bookmarks and pens.</small></p>
            <p class="has-text-right"><router-link to="/store" class="button is-link"><span>Store</span> <span class="icon"><i class="fas fa-chevron-right"></i></span></router-link></p>
        </div>
        </div>
        <div class="column is-3 content" v-if="$store.getters.hasSpelling">
            <h2>How To Use Spelling Lists</h2>
              <iframe width="100%" height="150" src="https://www.youtube.com/embed/E8d8XyOXjlo" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
          </div> -->
      <div v-if="$store.getters.hasEdShed" class="column is-6">
        <div class="box box-primary">
          <div class="box-header with-border content">
            <h2>Your Groups</h2>
            <div class="box-tools pull-right">
              <router-link tag="button" class="btn btn-box-tool" to="/groups">
                <i class="mdi mdi-grid" />
              </router-link>
            </div>
          </div>
          <div class="box-body">
            <b-table
              v-if="groupsData"
              :data.sync="groupsData"
              :paginated="true"
              :per-page="10"
              :mobile-cards="true"
              :striped="true"
              :selectable="false"
              :sort="false"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="Name" sortable>
                  <h4>{{ props.row.name }}</h4>
                </b-table-column>
                <b-table-column field="action" label="">
                  <router-link :to="'/groups/'+props.row.id" class="button is-link is-pulled-right">
                    <i class="fas fa-arrow-right" />
                  </router-link>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon custom-class="far" pack="fa" icon="frown" size="is-large" />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
            <div v-else class="alert">
              <b>{{ response }}</b>
            </div>
          </div>
        </div>
      </div>
      <div v-if="$store.getters.hasSpelling" class="column is-6">
        <div class="box box-info">
          <div class="box-header with-border content">
            <h2>Your Lists</h2>
            <div class="box-tools pull-right">
              <router-link tag="button" class="btn btn-box-tool" to="/lists">
                <i class="mdi mdi-newspaper" />
              </router-link>
            </div>
          </div>
          <div class="box-body">
            <b-table
              v-if="listsData"
              :data.sync="listsData"
              :paginated="true"
              :per-page="5"
              :mobile-cards="true"
              :striped="true"
              :selectable="false"
              :sort="false"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="Name" sortable>
                  <h4>{{ props.row.title }}</h4>
                  <p v-if="props.row.favourite == 1">
                    <i>@{{ props.row.owner }}</i>
                  </p><p>{{ props.row.word_count || 0 }} Word(s) <span v-if="props.row.favourite == 1">❤️</span></p>
                </b-table-column>
                <b-table-column field="action" label="">
                  <router-link :to="'/list/'+props.row.ident" class="button is-link is-pulled-right">
                    <i class="fas fa-arrow-right" />
                  </router-link>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon custom-class="far" pack="fa" icon="frown" size="is-large" />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div v-if="$store.getters.hasEdShed" class="column is-6">
        <div class="box box-warning">
          <div class="box-header with-border content">
            <h2>
              Assignments
              <br><span class="tag is-dark">PREMIUM</span>
            </h2>
            <div class="box-tools pull-right">
              <router-link tag="button" class="btn btn-box-tool" to="/homework">
                <i class="mdi mdi-clock" />
              </router-link>
            </div>
          </div>
          <div v-if="isPremium || $store.getters.hasNumber" class="box-body">
            <b-table
              v-if="homeworkData"
              :data.sync="homeworkData"
              :paginated="true"
              :per-page="5"
              :mobile-cards="true"
              :striped="true"
              :selectable="false"
              :sort="false"
            >
              <template slot-scope="props">
                <b-table-column class="content" field="name" label="Assignment" sortable>
                  <h4>{{ props.row.title }}</h4>
                  <p>List: {{ props.row.list_name }}</p>
                </b-table-column>
                <b-table-column field="score" label="Expiry" sortable>
                  <span :class="'tag is-' + dateInRange(props.row.available_from, props.row.expiry)">{{ dateFromServerDate(props.row.expiry) || 'No Expiry' }}</span>
                </b-table-column>
                <b-table-column field="actions" label="">
                  <router-link tag="button" :to="'/homework/' + props.row.id" type="button" class="button is-link is-pulled-right">
                    <i class="fas fa-arrow-right" />
                  </router-link>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon custom-class="far" pack="fa" icon="frown" size="is-large" />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
          <div v-else class="box-body text-center">
            <p>Upgrade to create and monitor assignments for your {{ $store.state.user.locale === 'en_GB' ? 'pupils' : 'students' }}.</p>
            <router-link
              v-if="isAdmin"
              to="/manage-subscription"
              class="button is-link"
              data-dismiss="modal"
            >
              Upgrade
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="$store.getters.hasSpelling" class="column is-6">
        <div class="box box-danger">
          <div class="box-header with-border content">
            <h2>
              Hive Games
              <br><span class="tag is-dark">PREMIUM</span>
            </h2>
            <div class="box-tools pull-right">
              <router-link tag="button" class="btn btn-box-tool" to="/hives">
                <i class="mdi mdi-sitemap" />
              </router-link>
            </div>
          </div>
          <div v-if="isPremium" class="box-body">
            <b-table
              v-if="hivesData"
              :data.sync="hivesData"
              :paginated="true"
              :per-page="5"
              :mobile-cards="true"
              :striped="true"
              :selectable="false"
              :sort="false"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="List">
                  {{ props.row.list_title }}
                </b-table-column>
                <b-table-column field="status" label="Status">
                  <span :class="(props.row.status === 'finished') ? 'tag is-danger' : 'tag is-success'">{{ (props.row.status === 'finished') ? 'Finished' : 'Active' }}</span>
                </b-table-column>
                <b-table-column field="created" label="Created">
                  <span class="is-size-7">{{ localeDate(props.row.created) }}</span>
                </b-table-column>
                <b-table-column field="actions" label="">
                  <router-link v-if="props.row.status === 'finished'" :to="'/hives/' + props.row.id" tag="button" class="button is-link is-pulled-right">
                    <i class="fas fa-arrow-right" />
                  </router-link>
                  <button v-else class="button is-success is-pulled-right" data-toggle="modal" data-target="#hiveGame" @click="showAddHiveModalWithList(props.row.list_ident)">
                    Continue
                  </button>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon
                        custom-class="far"
                        pack="fa"
                        icon="frown"
                        size="is-large"
                      />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
            <HiveGame v-if="hiveListIdent !== '' && showHiveModal" :list="hiveListIdent" @hideModal="hideHive" />
          </div>
          <div v-else class="box-body text-center">
            <p>Upgrade to view detailed data about your {{ $store.state.user.locale === 'en_GB' ? 'pupils' : 'students' }}.</p>
            <router-link
              v-if="isAdmin"
              to="/manage-subscription"
              class="button is-link"
              data-dismiss="modal"
            >
              Upgrade
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="$store.getters.hasSpelling" class="column is-6">
        <div class="box box-link">
          <div class="box-header with-border content">
            <h2>
              Top Spelling Groups
            </h2>
            <div class="box-tools pull-right">
              <router-link tag="button" class="btn btn-box-tool" to="/schoolleagues">
                <i class="mdi mdi-format-list-numbers" />
              </router-link>
            </div>
          </div>
          <div v-if="isPremium" class="box-body">
            <b-table
              v-if="topGroups"
              :data.sync="topGroups"
              :paginated="true"
              :per-page="10"
              :mobile-cards="true"
              :current-page.sync="topGroupsPage"
              :striped="true"
              :selectable="false"
              :sort="false"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="#" item-width="10px">
                  {{ props.index + (topGroupsPage - 1) * 10 + 1 }}.
                </b-table-column>
                <b-table-column field="group" label="Group">
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column field="score" label="Score">
                  {{ props.row.score.toLocaleString() }}
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon custom-class="far" pack="fa" icon="frown" size="is-large" />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
          <div v-else class="box-body text-center">
            <p>Upgrade to view detailed data about your {{ $store.state.user.locale === 'en_GB' ? 'pupils' : 'students' }}.</p>
            <router-link
              v-if="isAdmin"
              to="/manage-subscription"
              class="button is-link"
              data-dismiss="modal"
            >
              Upgrade
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="$store.getters.hasNumber" class="column is-6">
        <div class="box box-link">
          <div class="box-header with-border content">
            <h2>Top {{ $store.state.user.locale === 'en_US' ? 'Math' : 'Maths' }} Groups</h2>
            <div class="box-tools pull-right">
              <router-link tag="button" class="btn btn-box-tool" to="/schoolleagues">
                <i class="mdi mdi-format-list-numbers" />
              </router-link>
            </div>
          </div>
          <div class="box-body">
            <b-table
              v-if="topMathsGroups"
              :data.sync="topMathsGroups"
              :paginated="true"
              :per-page="10"
              :mobile-cards="true"
              :current-page.sync="topMathsGroupsPage"
              :striped="true"
              :selectable="false"
              :sort="false"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="#" item-width="10px">
                  {{ props.index + (topMathsGroupsPage - 1) * 10 + 1 }}.
                </b-table-column>
                <b-table-column field="group" label="Group">
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column field="score" label="Score">
                  {{ props.row.score_number.toLocaleString() }}
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon custom-class="far" pack="fa" icon="frown" size="is-large" />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div v-if="$store.getters.hasSpelling" class="column is-6">
        <div class="box box-success">
          <div class="box-header with-border content">
            <h2>
              Top Spellers
            </h2>
            <div class="box-tools pull-right">
              <router-link tag="button" class="btn btn-box-tool" to="/schoolleagues">
                <i class="mdi mdi-format-list-numbers" />
              </router-link>
            </div>
          </div>
          <div v-if="isPremium" class="box-body">
            <b-table
              v-if="topSpellers"
              :data.sync="topSpellers"
              :paginated="true"
              :per-page="10"
              :current-page.sync="topSpellersPage"
              :mobile-cards="true"
              :striped="true"
              :selectable="false"
              :sort="false"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="#" item-width="10px">
                  {{ props.index + (topSpellersPage - 1) * 10 + 1 }}.
                </b-table-column>
                <b-table-column :key="props.row.id" field="avatar" label="">
                  <AvatarView v-if="props.row.total_score != null" style="float:left; margin-right:10px;" :uniq="'userx-'+props.row.id" :data="(props.row.avatar && props.row.avatar != '') ? JSON.parse(props.row.avatar) : {}" scale-factor="0.35" />
                </b-table-column>
                <b-table-column field="pupil" label="Pupil">
                  {{ props.row.display_name ? props.row.display_name : props.row.name }}
                </b-table-column>
                <b-table-column field="groups" label="Groups">
                  {{ props.row.groups || '' }}
                </b-table-column>
                <b-table-column field="score" label="Score">
                  {{ props.row.score.toLocaleString() }}
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon custom-class="far" pack="fa" icon="frown" size="is-large" />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
          <div v-else class="box-body text-center">
            <p>Upgrade to view detailed data about your {{ $store.state.user.locale === 'en_GB' ? 'pupils' : 'students' }}.</p>
            <router-link
              v-if="isAdmin"
              to="/manage-subscription"
              class="button is-link"
              data-dismiss="modal"
            >
              Upgrade
            </router-link>
          </div>
        </div>
      </div>
      <div v-if="$store.getters.hasNumber" class="column is-6">
        <div class="box box-success">
          <div class="box-header with-border content">
            <h2>Top Mathstronauts</h2>
            <div class="box-tools pull-right">
              <router-link tag="button" class="btn btn-box-tool" to="/schoolleagues">
                <i class="mdi mdi-format-list-numbers" />
              </router-link>
            </div>
          </div>
          <div class="box-body">
            <b-table
              v-if="topMaths"
              :data.sync="topMaths"
              :paginated="true"
              :per-page="10"
              :current-page.sync="topMathsPage"
              :mobile-cards="true"
              :striped="true"
              :selectable="false"
              :sort="false"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="#" item-width="10px">
                  {{ props.index + (topMathsPage - 1) * 10 + 1 }}.
                </b-table-column>
                <b-table-column :key="'m' + props.row.id" field="avatar" label="">
                  <AvatarView v-if="props.row.total_score != null" style="float:left; margin-right:10px;" :uniq="'muser-'+props.row.id" :data="(props.row.avatar && props.row.avatar != '') ? JSON.parse(props.row.avatar) : {}" scale-factor="0.35" />
                </b-table-column>
                <b-table-column field="pupil" label="Pupil">
                  {{ props.row.display_name ? props.row.display_name : props.row.name }}
                </b-table-column>
                <b-table-column field="groups" label="Groups">
                  {{ props.row.groups || '' }}
                </b-table-column>
                <b-table-column field="score_number" label="Score">
                  {{ props.row.score_number.toLocaleString() }}
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon custom-class="far" pack="fa" icon="frown" size="is-large" />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import dateFormat from 'dateformat'
import moment from 'moment'
import Chartist from 'chartist'
import VueChartist from 'v-chartist'
import _ from 'lodash'
import HiveGame from '@/components/views/components/HiveGame'
import AvatarView from '@/components/views/AvatarView'
import { request } from '@/edshed-common/api'

export default {
  name: 'Dashboard',
  components: {
    VueChartist,
    Chartist,
    HiveGame,
    AvatarView
  },
  data (router) {
    return {
      user: this.$store.state.user,
      groupsData: null,
      response: '',
      topGroups: null,
      topGroupsPage: 1,
      topMathsGroups: null,
      topMathsGroupsPage: 1,
      topSpellers: null,
      topMaths: null,
      topSpellersPage: 1,
      topMathsPage: 1,
      homeworkData: null,
      activityData: null,
      mathsActivityData: null,
      listsData: null,
      hivesData: null,
      chart: null,
      hiveListIdent: '',
      showHiveModal: false,
      subsData: null,
      connectionRequested: false,
      litshedSearchText: ''
    }
  },
  computed: {
    expiryDate () {
      const expDate = this.$store.state.user.school.subscriptions[this.$store.state.user.school.subscriptions.length - 1].expiry
      return dateFormat(expDate, 'dS mmm yyyy')
    },
    hasCancelled () {
      const sub = this.$store.state.user.school.subscriptions[this.$store.state.user.school.subscriptions.length - 1]
      // console.log(sub)
      if (sub.cancellation_date) {
        return true
      } else {
        return false
      }
    },
    isPremium () {
      return this.$store.getters.isPremium
    },
    isAdmin () {
      return (this.$store.state.user.school.admin === 1)
    },
    canTrialMaths () {
      if (!this.subsData) {
        return false
      }
      if (this.$store.getters.hasNumber) {
        return false
      }
      if (this.subsData.filter(sub => sub.number === 1).length > 0) {
        return false
      }
      return true
    },
    canTrialSpelling () {
      if (!this.subsData) {
        return false
      }
      if (this.$store.getters.hasSpelling) {
        return false
      }
      if (this.subsData.filter(sub => sub.spelling === 1).length > 0) {
        return false
      }
      return true
    }
  },
  mounted () {
    if (this.$store.state.user.superuser) {
      this.$router.push('/subscriptions')
    } else if (this.$store.state.user.dictionary_editor) {
      this.$router.push('/dictionary')
    }
    this.$nextTick(() => {
      this.getGroupsData()
      this.getLists()
      this.getAllSubscriptions()
      if (this.isPremium) {
        this.getTopGroups()
        this.getTopSpellers()
        this.getHomeworkData()
      } else if (this.$store.getters.hasNumber) {
        this.getTopGroups()
        this.getHomeworkData()
        // this.getTopSpellers()
      }
      if (this.$store.getters.hasNumber) {
        this.getMathsActivityData()
        this.getTopMathsers()
      }

      if (this.$store.getters.hasSpelling) {
        this.getActivityData()
      }
      window.dispatchEvent(new Event('resize'))
    })
  },
  methods: {
    wondeRequest () {
      if (!this.$store.getters.hasFullSubscription) {
        alert('Wonde connection are available with full subscriptions.')
        return
      }
      console.log('Wonde requested')
      const c = confirm('Please confirm that you would like your account linked to your Wonde account. This may take a few days to complete and any existing school data may not be deleted.')
      if (c) {
        this.connectionRequested = true
        request('POST', 'users/me/school/linkwonde', null)
          .then((response) => {
            alert('Request sent. We will be in touch if we require further information.')
          })
          .catch((error) => {
            console.log(error)
            this.connectionRequested = false
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
          })
      }
    },
    cleverRequest () {
      if (!this.$store.getters.hasFullSubscription) {
        alert('Clever connection are available with full subscriptions.')
        return
      }
      console.log('Clever requested')
      const c = confirm('Please confirm that you would like your account linked to your Clever account. This may take a few days to complete and any existing school data may not be deleted.')
      if (c) {
        this.connectionRequested = true
        request('POST', 'users/me/school/linkclever', null)
          .then((response) => {
            alert('Request sent. We will be in touch if we require further information.')
          })
          .catch((error) => {
            console.log(error)
            this.connectionRequested = false
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
          })
      }
    },
    cleverSync () {
      if (!this.$store.state.user.school.clever_oid) {
        return
      }
      request('POST', 'schools/' + this.$store.state.user.school.clever_oid + '/cleversync', null)
        .then((response) => {
          const data = response.data
          console.log(data)
          // this.searchDidChange()
          // console.log('readerData: ' + this.readerData)
          this.$buefy.toast.open({ message: 'Success', type: 'is-success' })
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    startMathsTrial () {
      const c = confirm('Do you wish to start a FREE 30 day trial? \nN.B. You can only do this once.')
      if (c) {
        request('POST', 'users/me/school/subscriptions/trialmaths', null)
          .then((response) => {
            const data = response.data
            if (data.user) {
              this.$store.commit('SET_USER', data.user)
              // console.log('user: ' + JSON.stringify(data.user))
              if (window.localStorage) {
                window.localStorage.setItem('user', JSON.stringify(data.user))
              }
            }
            this.getAllSubscriptions()
            alert('Trial started successfully')
          })
          .catch((error) => {
            console.log(error)
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
          })
      }
    },
    startSpellingTrial () {
      const c = confirm('Do you wish to start a FREE 30 day trial? \nN.B. You can only do this once.')
      if (c) {
        request('POST', 'users/me/school/subscriptions/trialspelling', null)
          .then((response) => {
            const data = response.data
            if (data.user) {
              this.$store.commit('SET_USER', data.user)
              // console.log('user: ' + JSON.stringify(data.user))
              if (window.localStorage) {
                window.localStorage.setItem('user', JSON.stringify(data.user))
              }
            }
            this.getAllSubscriptions()
            alert('Trial started successfully')
          })
          .catch((error) => {
            console.log(error)
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
          })
      }
    },
    getAllSubscriptions () {
      request('GET', 'users/me/school/subscriptions', null)
        .then((response) => {
          const data = response.data
          this.subsData = data.subscriptions
          console.log('subscription data: ' + this.subsData)
        })
        .catch((error) => {
          console.log(error)

          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
        })
    },
    getLists () {
      this.listData = []
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/lists', null)
        .then((response) => {
          const data = response.data
          this.listsData = data.lists.map(this.numberIfy)
          this.listsData = _.sortBy(this.listsData, [function (o) { return o.title }])
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
          this.getFavouriteListData()
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getFavouriteListData () {
      this.listData = []
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/lists/favourites', null)
        .then((response) => {
          const data = response.data
          this.listsData = this.listsData.concat(data.lists.map(this.numberIfy))
          this.listsData = _.sortBy(this.listsData, [function (o) { return o.title }])
          // this.listData = this.listData.concat(data.lists)
          // console.log('readerData: ' + this.readerData)
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getGroupsData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/groups', null)
        .then((response) => {
          const data = response.data
          this.groupsData = data.groups

          // console.log('readerData: ' + this.readerData)
          if (!this.groupsData || this.groupsData.length === 0) {
            this.response = 'No Groups'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },

    localeDate (dt) {
      const m = moment(dt)
      const dts = m.toDate().toLocaleDateString() + ' ' + m.format('HH:mm')
      return dts // m.format('DD/MM/YYYY HH:mm')
    },
    getTopGroups () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/leaderboards/school', null)
        .then((response) => {
          const data = response.data
          this.topGroups = data.groups
          this.topMathsGroups = []
          this.topMathsGroups = this.topMathsGroups.concat(data.groups)
          this.topMathsGroups = _.sortBy(this.topMathsGroups, [function (o) { return o.score_number }]).reverse()
          // console.log('readerData: ' + this.readerData)
          if (!this.topGroups || this.topGroups.length === 0) {
            //           this.response = 'No Groups'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    getTopSpellers () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/leaderboards/school/users', null)
        .then((response) => {
          const data = response.data
          this.topSpellers = data.pupils
          // this.topMaths = [].concat(data.pupils)
          // this.topMaths = this.topMaths.sort((a, b) => (a.score_number < b.score_number) ? 1 : ((b.score_number < a.score_number) ? -1 : 0))
          // console.log('readerData: ' + this.readerData)
          if (!this.topSpellers || this.topSpellers.length === 0) {
            //           this.response = 'No Pupils'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    getTopMathsers () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/leaderboards/school/users/maths', null)
        .then((response) => {
          const data = response.data
          this.topMaths = data.pupils
          // this.topMaths = [].concat(data.pupils)
          // this.topMaths = this.topMaths.sort((a, b) => (a.score_number < b.score_number) ? 1 : ((b.score_number < a.score_number) ? -1 : 0))
          // console.log('readerData: ' + this.readerData)
          if (!this.topSpellers || this.topSpellers.length === 0) {
            //           this.response = 'No Pupils'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()

          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }

          this.response = 'Details incorrect'
        })
    },
    getHomeworkData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'homework', null)
        .then((response) => {
          const data = response.data
          this.homeworkData = []
          for (const d of data.homework) {
            if (d.owner_id === this.$store.state.user.id) {
              this.homeworkData.push(d)
            }
          }
          if (!this.homeworkData || this.homeworkData.length === 0) {
            this.response = 'No Homework'
          }
          // console.log('readerData: ' + this.readerData)
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getActivityData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/activity', null)
        .then((response) => {
          const data = response.data
          if (data.user) {
            this.$store.commit('SET_USER', data.user)
            // console.log('user: ' + JSON.stringify(data.user))
            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
            }
          }
          this.activityData = data.data
          this.renderActivityChart()
          // console.log('readerData: ' + this.readerData)
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getMathsActivityData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/me/school/activitymaths', null)
        .then((response) => {
          const data = response.data
          if (data.user) {
            this.$store.commit('SET_USER', data.user)
            // console.log('user: ' + JSON.stringify(data.user))
            if (window.localStorage) {
              window.localStorage.setItem('user', JSON.stringify(data.user))
            }
          }
          this.mathsActivityData = data.data
          this.renderActivityChart()
          // console.log('readerData: ' + this.readerData)
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response && error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    renderActivityChart () {
      if (!this.activityData && !this.mathsActivityData) {
        return
      }
      const labels = []
      const seriesData = []
      const mathsSeriesData = []
      for (let i = 0; i < this.activityData.length; i++) {
        if (i % 3 === 0) {
          labels.push(moment(this.activityData[i].date).format('D/M'))
        } else {
          labels.push('')
        }
        if (this.$store.getters.hasNumber && this.mathsActivityData) {
          mathsSeriesData.push(parseInt(this.mathsActivityData[i].count))
        }
        if (this.$store.getters.hasSpelling && this.activityData) {
          seriesData.push(parseInt(this.activityData[i].count))
        }
      }

      const series = [seriesData, mathsSeriesData]
      const data = { labels, series }
      console.log(data)
      this.chart = Chartist.Bar('#chart1', data)
    },
    dateFromServerDate (dt) {
      if (dt === null || dt === '' || !moment(dt).isValid()) {
        return null
      }
      const m = moment(dt)
      // var utcOffset = new Date().getTimezoneOffset()
      // m.add({minutes: utcOffset})
      const dts = m.toDate().toLocaleDateString() + ' ' + m.format('HH:mm')
      return dts // m.format('HH:mm')
    },
    dateInRange (start, end) {
      let startDate = null
      if (start) {
        startDate = moment(start)
      }
      const now = moment()

      let expiry = null
      if (end) {
        expiry = moment(end)
      }
      if (start && startDate > now) {
        return 'default'
      } else if (end && expiry < now) {
        return 'danger'
      } else {
        return 'success'
      }
    },
    numberIfy (item, index) {
      item.rating = parseFloat(item.rating)
      return item
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    },
    showAddHiveModalWithList (listIdent) {
      this.hiveListIdent = listIdent
      this.showHiveModal = true
    },
    hideHive () {
      this.hiveListIdent = ''
      this.showHiveModal = false
    },
    searchLitshed () {
      const t = this.litshedSearchText
      if (t) {
        window.location.href = 'https://www.literacyshedplus.com/en-gb/search?q=' + encodeURIComponent(t)
      }
    }
  }
}
</script>
<style>
  .info-box-icon.bg-primary {
    background-color: #00d1b2;
  }
  .info-box-number, .info-box-text {
    font-size: 12px;
  }
</style>
